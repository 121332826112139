<template>
    <BFormGroup
        :label="label"
        :label-for="id"
        :description="hint"
    >
        <div
            class="custom-multiselect bg-white border-input"
            :style="`--custom-multiselect-height: ${selectSize * 1.5}rem`"
            tabindex="0"
        >
            <div
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                @click.prevent="(event) => onOptionClick(event, option)"
                class="position-relative custom-multiselect-option"
                :class="{ 'text-white bg-primary': model.includes(option.value) }"
            >
                {{option.text}}
                <button
                    v-if="option.hint"
                    class="text-aspena-orange ml-1 p-0 bg-transparent border-0 d-inline-block"
                    :id="`${id}_option_${option.value}`"
                    v-b-tooltip.hover.click="option.hint"
                >
                    <i class="iconify" inline="true" data-icon="bi:info-circle"></i>
                </button>
            </div>
        </div>
    </BFormGroup>
</template>

<script>
export default {
    name: 'CustomInputSelect',
    props: {
        options: {
            type: Array,
            required: false,
            default: () => []
        },
        label: {
            type: String,
            required: false
        },
        value: {
            required: false
        },
        id: {
            type: String,
            required: false
        },
        selectSize: {
            type: Number,
            required: false,
            default: 4
        },
        hint: {
            type: String,
            required: false
        }
    },
    computed: {
        model: {
            get () {
                return this.value || null
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onOptionClick (event, option) {
            if (event.target.classList.contains('custom-multiselect-option')) {
                event.target.parentElement.focus()
                this.$root.$emit('bv::hide::tooltip')
                if (event.ctrlKey) {
                    if (this.model.includes(option.value)) {
                        this.model = this.model.filter(item => item !== option.value)
                    } else {
                        this.model = [...this.model, option.value]
                    }
                } else {
                    this.model = [option.value]
                }
            }
        }
    }
}
</script>
