<template>
    <div class="row">
        <div class="col-12" v-if="heading">
            <h4>
                {{heading}}
            </h4>
        </div>
        <div class="col-12 col-md-6 mb-4 mb-md-0">
            <CustomInputSelect
                class="mb-2"
                :options="availableOptions"
                :label="label"
                :id="id"
                :hint="hint"
                v-model="selected"
                :select-size="5"
            />
            <b-form-input
                class="mb-2"
                size="sm"
                :placeholder="$t('multiselect.searchPlaceholder')"
                v-model.trim="searchTerm"
            />
            <p>
                <aspena-btn
                    size="sm"
                    variant="aspena-orange"
                    @click.prevent="onAdd"
                >
                   {{$t('multiselect.add')}}
                </aspena-btn>
            </p>
        </div>
        <div class="col-12 col-md-6">
            <p class="text-bold text-aspena-blue mb-2">
                {{$t('multiselect.selectedHeading')}} <span v-if="maxItems">({{modelValue.length}} / {{maxItems}})</span>
            </p>
            <p class="alert alert-primary" v-if="selectedOptions.length === 0 && (!validation || !validation.$invalid)">
                {{$t('multiselect.nothingSelected')}}
            </p>
            <p class="alert alert-danger" v-if="selectedOptions.length === 0 && validation && validation.$invalid">
                {{$t((this.maxItems === 1) ? 'multiselect.selectOne' : 'multiselect.selectAtLeastOne')}}
            </p>
            <p class="d-flex flex-wrap" v-if="selectedOptions.length > 0">
                <b-badge
                    v-for="item in selectedOptions"
                    :key="item.value"
                    variant="primary"
                    class="d-flex align-items-center align-content-center mr-2 mb-2 p-2"
                >
                    <div>{{item.text}}</div>
                    <div class="ml-2">
                        <b-btn
                            variant="light"
                            size="sm"
                            class="text-danger"
                            @click.prevent="onRemove(item)"
                        >
                            <b-icon icon="trash"></b-icon>
                        </b-btn>
                    </div>
                </b-badge>
            </p>
        </div>
    </div>
</template>

<script>
import { FormInputSelect } from '@tvaliasek/vue-form-inputs'
import CustomInputSelect from './CustomInputSelect'

export default {
    name: 'MultiselectEditor',
    extends: FormInputSelect,
    components: { CustomInputSelect },
    props: {
        heading: {
            type: String,
            required: false
        },
        maxItems: {
            type: Number,
            required: false
        }
    },
    data () {
        return {
            selected: [],
            searchTerm: null
        }
    },
    computed: {
        modelValue: {
            get () {
                return this.value || []
            },
            set (value) {
                this.$emit('input', [...new Set(value)])
            }
        },
        availableOptions () {
            return this.options.filter(item => this.modelValue.indexOf(item.value) === -1).filter(item => {
                if (!this.searchTerm) {
                    return true
                }
                return `${item.text}`.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
            })
        },
        selectedOptions () {
            if (!this.value) {
                return []
            }
            return this.options.filter(item => this.modelValue.indexOf(item.value) > -1)
        }
    },
    methods: {
        onRemove (item) {
            this.modelValue = this.modelValue.filter(entry => entry !== item.value)
            this.$nextTick(() => {
                this.$emit('changed')
            })
        },
        onAdd () {
            this.modelValue = [...new Set([...this.modelValue, ...this.selected])].filter((item, index) => {
                if (!this.maxItems) {
                    return true
                }
                return index < this.maxItems
            })
            this.$nextTick(() => {
                this.selected = []
                this.searchTerm = null
                this.$emit('changed')
            })
        }
    }
}
</script>
